import logo from './logo.svg';
import './App.css';
// import describe from "./api";
import { useEffect } from "react";

function App() {
  useEffect(async () => {
    //  fetch()

  }, [])
  // http://mirrors.hostever.com/centos/7.9.2009/isos/x86_64/CentOS-7-x86_64-Minimal-2009.iso
  return (
    <div className="App">
      Hello
    </div>
  );
}

export default App;
